
function main() {
  let mapElmnt = document.getElementById('map-image');
  let mapStyle = "feature:administrative|element:all|visibility:on|lightness:33&style=feature:landscape|element:all|color:0xefefef&style=feature:poi|element:labels.text.fill|color:0x51626f&style=feature:poi|element:labels.icon|color:0x51626f&style=feature:poi.park|element:geometry|color:0xedebdc&style=feature:poi.park|element:labels|visibility:on|lightness:20&style=feature:poi.park|element:labels.text.fill|color:0x7d99af&style=feature:road|element:all|lightness:20&style=feature:road.highway|element:geometry|color:0xb2c3c8&style=feature:road.highway|element:labels.text.fill|color:0x9f985d&style=feature:road.arterial|element:geometry|color:0xbdcdd3&style=feature:road.local|element:geometry|color:0xffffff&style=feature:water|element:all|visibility:on|color:0xb5cbe4&style=feature:water|element:geometry.fill|color:0xd3dee3";

  if (mapElmnt != null || typeof mapElmnt != undefined ) { // If the map element is not null or undefined, get its width and parse image
    let apiOpt = {
      apiKey: "AIzaSyATiIfVBr9-sonZ1x_rcseOd1b3oPFO5uo",
      lat: "39.949980",
      lng: "-75.201590",
      sizeX: `${mapElmnt.width}`,
      sizeY: `${mapElmnt.height}`,
      style: mapStyle
    };

    let apiUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${apiOpt.lat},${apiOpt.lng}&zoom=12&size=${apiOpt.sizeX}x${apiOpt.sizeY}&maptype=roadmap&key=${apiOpt.apiKey}&style=${apiOpt.style}`;

    const mapImage = document.getElementById("map-image");
          mapImage.src = apiUrl; // Set the image
          mapElmnt.classList.remove('map__image--hidden'); //Remove hiden class for smooth reveal
  }
}

window.onload = function() {
    main();
}