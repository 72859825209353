function mapBox() {

  mapboxgl.accessToken = 'pk.eyJ1IjoiZGNtYnJvbDAwIiwiYSI6ImNsYnBhMDVhZjA4ZGUzcG51enNzMTRpdDAifQ.yfvLoxK99cB05hXFzsKLKQ';
  let mapLng = '-75.369889';
  let mapLat = '40.041600';

  let map = new mapboxgl.Map({
    container: 'map-image',
    style: 'mapbox://styles/dcmbrol00/cldaeszwo000501phcbi6tqf2',
    zoom: 9,
    center: [mapLng, mapLat],
    interactive: false
  });

  let mapElmnt = document.getElementById('map-image');

  if (mapElmnt != null || typeof mapElmnt != undefined ) { // If the map element is not null or undefined, get its width and parse image
    map.on('load', function() {

      map.addSource('points', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [
            {
              // feature
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': [
                  mapLng,
                  mapLat
                ]
              },
              'properties': {
                'radius': 1550
              }
            }
          ]
        }
      });

      map.addLayer({
        'id': 'demo',
        'type': 'circle',
        'source': 'points',
        'paint': {
          // use get expression to get the radius property. Divided by 10 to be able to display it
          "circle-color": "#878eb5",
          "circle-stroke-width": 100,
          "circle-stroke-color": '#b7bcd9',
          "circle-stroke-opacity": 0.25,
          "circle-opacity": 0.4,
          'circle-radius': ['/',['get', 'radius'],10]
        }
      });

    });
  }
  map.resize();
  map.scrollZoom.disable();
}

window.onload = function() {
  mapBox();
}
